import React from "react"
import Layout from "../components/Layout"
import Splash from "../components/Splash"

export default function IndexPage() {
  return (
    <Layout page="home" bgColor="inherit">
      <section>
        <Splash />
      </section>
    </Layout>
  )
}