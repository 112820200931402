import React from "react"
import { Link } from "gatsby"
import Login from '../components/Login'
import splashStyles from "../styles/components/splash.module.scss"
import Img from 'gatsby-image'
import { Container, Grid } from '@material-ui/core'

const isAuthenticated = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('isAuthenticated') === 'true';
  } else {
    return false;
  }
};

class Splash extends React.Component {      

  render() {        
    if (!isAuthenticated()) {
      return (        
        <Container maxWidth="lg">
          <div className={splashStyles.gridWrapper}>
            <Grid 
              container spacing={3} 
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={12} md={6}>
                <h1>Stories You’ve Never Heard<br />And Some You Wish You Hadn’t</h1>
                <p>Pre-Register for access to a new Hollywood insider podcast everyone will be talking about.</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <Login/>            
              </Grid>        
            </Grid>
          </div>      
        </Container>
      )
    }

    return (
      <Container maxWidth="lg">
        <div className={splashStyles.gridWrapper}>
          <Grid 
            container spacing={3} 
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item xs={12} md={6}>
              <h1>Prepare Yourself<br />For More Good</h1>
              <p>Check back often. We'll send you an email when we launch!</p>
            </Grid>
            <Grid item xs={12} md={6}>

              
            </Grid>        
          </Grid>
        </div>      
      </Container>
    )
  }
}

export default Splash